import { gql } from '@apollo/client'

const resHead = `status,message `
const resPage = `total`
const rscFields = ` createdBy, createdAt  `

const rsvCols = `id, type,ownerId,ownerName, title  ,
shopId, shopTitle, schdBegAt, carId,carRegNo,
washType,washOpts,washCourse,amount,unit, cpnId
payAmount, payUnit, stat,   code

`

const taskOptCols = ` washOpts {
  washOptId, washOptTitle, washOptPrice
} `

const taskCols = `task {
 id, refType,refId, shopId, title, schdBegAt, schdEndAt, stat,
 carId, carType, carRegNo, carModel, carTitle, carOptCodes,
 washType, washCourseId, washCourseTitle, washCoursePrice ,
 ${taskOptCols}
}`

const payCols = `id, itemType,itemId, amount, unit ,
 type, cpnId,cardType,cardName, cardNo, cardHolder, method, stat,
 prepaidId, txId, txStat,txErr
`

const washPodCol = `id,shopId,regNo,model,modelName,title,note,type,stat,authCode,validWashCount,ownerId,ownerName,
carTypes
`

const worktimeCols = `id, shopId, type, title, week, begAt,endAt,stat`

const courseCols = `id, code, type, shopId, title, note, price, childCodes,
createdAt, createdBy, ownerId, ownerName`

//cmRsv_findRsv(req: CmRsvFindReq): CmRsvRsRes

const shopStatCols = ` id, isWork, rsvCount, waitCount, limitCount,avgScore `

const shopinfoCols = ` id, shopId, coRegNo, coPresident, tel, email, privacyAdmin, bizType, bizItem,
coRegDocId, coRegDocTitle, coBankAccNo, coBankAccImageId, coBankAccImageTitle, coBankName, coBankHolderName,
 addrCode, addrText, addrDetail, note, vanOn,vanType,vanName,vanMid,vanMerchantKey,vanRegAt, coCardMid, coCardMidNoAuth, pgName`

const partnerCols = `id, username, password, name, email, gids, roles, stat`

//const equipCols='id, type, code, title, stat, qty, unit ';
const equipCols = `id, shopId, type, code, title, qty, unit, note,stat`

const washCourseFld = `id, pid, code, shopId,
 title,type, washPodId,washPodModel, price,note,ownerName,ownerId,stat
 childCodes ${rscFields}`

const washOptFld = `id, pid, code, shopId,
 title,type,washPodId,washPodModel,price,note,stat
 childCodes ${rscFields}
 ownerId,ownerName`

const shopPrimeCols = `id, type,ownerId,ownerName,score,scoreCount,realPodCount,
 title,partnerId,regNo,loc,locTitle, lat,lng, podCount,inPodCount, capa, stat,
 addrCode, addrText,addrDetail, note, score,scoreCount, workTime,phone, createdAt, createdBy
 `
const carFlds = `id, type, kind, title, model, regNo, note, isDefault, opts,  ownerId, ownerName, createdAt, createdBy`
//washType,washOpts,washCourse, washCourseOpts ,
const washCols = `id, shopId,shopTitle, washPodId,washPodTitle, washPodRegNo,
    createdAt,createdBy,ownerId,ownerName,
    orderType,orderRefId,payType,payId, payAmount,payUnit, paid,
    price, priceUnit,
    schdBegAt,schdEndAt,
    treatBegAt,treatEndAt,customerId,customerName,
    carId,carRegNo,step,stepTotal, note,
    rsvId,
    stat,
    rsv { ${rsvCols}},
    ${taskCols},
    pays { ${payCols}},
    car { ${carFlds}},
    washPod{${washPodCol}}
    shop { ${shopPrimeCols} }

  `

const fileRefFlds = `
  id,type,name,size,refType,refId,url,stat
`
const shopCols = ` ${shopPrimeCols}
 washEquipDetails, washEquipOpts,washValidDetails,washValidOpts,myFavor,imageUrl,
 partner { ${partnerCols} },
 workStat { ${shopStatCols} },
 washCourses { ${washCourseFld} },
 washOpts { ${washOptFld} } ,
 equips { ${equipCols} } ,
 workTimes  { ${worktimeCols} } ,
 files{${fileRefFlds}},
 shopInfo { ${shopinfoCols} },
 settleSum4Dash{totalAmount,paidAmount,settledAmount,leftAmount},
 salesSum4Dash{totalAmount,paidAmount,settledAmount,leftAmount},
 washStat4Dash {countWash,countLeftWash,countError,countCancel}
 rsvCount,rsvDateAt,isRecommand,vendorId
 `

/************************
 * Hello
 */
export const cmn_hello = gql`
  query cmn_hello {
    cmn_hello
  }
`
export const cmn_putHello = gql`
  mutation cmn_putHello {
    cmn_putHello
  }
`
/************************
 * Auth
 */
export const auth_login = gql`
mutation auth_login($req:LoginReq!) {
  auth_login( req: $req) {
      ${resHead}
      data { username, token, refreshToken, roles, authRole, authLevel, gids, name,email, hp, imageUrl }
      # data { username, token, refreshToken, roles, gids, name,email, hp, imageUrl }
    }
  }
`

const loginCols =
  'username, token, refreshToken, roles, authRole, authLevel,  email'
export const auth_loginByToken = gql`
mutation auth_loginByToken($req:LoginReq!) {
  auth_loginByToken( req: $req) {
      ${resHead}
      data { ${loginCols} }
    }
  }
`

export const auth_logout = gql`
mutation auth_logout($req:LoginReq!) {
  auth_logout( req: $req) {
    ${resHead}
     data
    }
  }
`

/************************
 * User
 */
const settCols = `isNoticeNotiOn, isEventNotiOn,isShopNotiOn,isPayNotiOn, isWashNotiOn,isAdOn,isLocOn`
const carsCols = `id,code,type,kind,title,model,regNo,note,opts,isDefault,ownerId,ownerName,createdAt,createdBy`
const paysCols = `id,itemType,itemId,amount,unit,type,cpnId,cardType,cardName,cardNo,cardHolder,method,stat,prepaidId,body,txId
,txStat,txErr,shopId,kioskId,washPodId,ownerId,ownerName,createdAt,createdBy`
const rsvsCols = `id,code,type,title,note,shopId,shopTitle,schdBegAt,schdBegAt,carId,carRegNo,washType,washCourse,washCourse,amount,unit,
payAmount,payUnit,payStat,cpnId,taskId,stat,ownerId,ownerName,createdAt,createdBy,isToday`
const cpnPays = `id,itemType,itemId,amount,unit,type,cpnId,cardType,cardName,cardNo,cardHolder,method,stat,prepaidId,body,txId,txStat,txErr,shopId,kioskId,washPodId,ownerId,ownerName,createdAt,
createdBy`
const cpnTask = `id,refType,refId,shopId,title,schdBegAt,schdEndAt,carId,carType,carRegNo,carModel,carTitle,carOptCodes,washType,washCourseId,washCourseTitle,washCoursePrice,
washOptId,washOptTitle,stat,createdAt,createdBy,ownerId,ownerName`
const cpnsCols = `id,code,type,title,usedCount,validCount,usedStat,locTitle,loc,shopTitle,shopId,carRegNo,carId,taskId,price,
unit,payAmount,payUnit,payStat,note,nextPayDate,ownerId,ownerName,createdAt,createdBy,stat,pays {${cpnPays}},task{${cpnTask}}`
const cashesCols = `id,type,amount,unit,note,usedAmount,payAmount,payUnit,stat,ownerId,ownerName,createdAt,createdBy`
const reviewsCols = `id,bid,code,type,subType,title,body,email,refType,refId,ownerId,ownerName,createdAt,createdBy,stat,score,hitCount,favorCount,likeCount,replyCount,myLike,myFavor,`

const userCols = `id, username, name, email, phone, photo,
    addrCode, addrText,addrDetail,birthDate, shopId ,shopTitle, roles, authRole, authLevel,
    setting { ${settCols} }`

const deviceCols = `id,type,deviceId,regNo,title`

const totalUserCols = `id, type,username, name, email, roles, authRole, authLevel, phone, photo, payAmount, cashAmount, createdAt, updatedAt, regSnsType,info { id, dropoutAt, dropoutReason  }
addrCode, addrText,addrDetail,birthDate, shopId ,shopTitle, stat, companyMail, cars { ${carsCols} }, setting { ${settCols} }, pays { ${paysCols} },
rsvs {${rsvsCols}}, cpns {${cpnsCols}}, cpnsTotal, cashes {${cashesCols}}, device {${deviceCols}},
reviews {${reviewsCols}} reviewsTotal, shops {${shopCols}}, newShops {${shopCols}},
washesTotal`

// cards, shops
export const cmUser_getUser = gql`
 query cmUser_getUser( $req: CmUserReq! ) {
  cmUser_getUser( req: $req ) {
     ${resHead}
     data {  ${totalUserCols}  }
   }
 }
`

export const cmUser_findUser = gql`
 query cmUser_findUser( $req: CmUserFindReq) {
  cmUser_findUser( req: $req ) {
     ${resHead} ${resPage}
     data {  ${totalUserCols}  }
   }
 }
`

export const cmUser_addUser = gql`
mutation cmUser_addUser( $req: CmUserReq!) {
  cmUser_addUser( req: $req ) {
     ${resHead}
     data {  ${userCols}  }
   }
 }
`

export const cmUser_regUser = gql`
mutation cmUser_regUser( $req: CmUserRegReq! ) {
  cmUser_regUser( req: $req ) {
    ${resHead}
    data {   ${userCols}  }
  }
}
`

export const cmUser_putUser = gql`
mutation cmUser_putUser( $req: CmUserReq! ) {
 cmUser_putUser( req: $req ) {
    ${resHead}
    data {   ${userCols}  }
  }
}
`

export const cmUser_putUserPwd = gql`
mutation cmUser_putUserPwd( $req: CmUserReq! ) {
 cmUser_putUserPwd( req: $req ) {
    ${resHead}
    data {   ${userCols}  }
  }
}
`

export const cmUser_delUser = gql`
mutation cmUser_delUser( $req: CmUserReq! ) {
  cmUser_delUser( req: $req ) {
    ${resHead}
    data {   ${userCols}  }
  }
}
`

export const cmn_getUser = gql`
 query cmn_getUser( $req:UserReq ) {
  cmn_getUser( req: $req ) {
     ${resHead}
     data {  id, username  }
   }
 }
`

export const pds_putFile = gql`
  mutation pds_putFile ($req:RzFileReq){
    pds_putFile(req:$req){
      ${resHead}
      data { ${fileRefFlds} }
    }
  }
`

export const pds_delFile = gql`
  mutation pds_delFile ($req:RzFileReq){
    pds_delFile(req:$req){
      ${resHead}
      data { ${fileRefFlds} }
    }
  }
`

/************************
 * Car
 */

export const cmUser_findCar = gql`
query cmUser_findCar( $req:CmCarFindReq ) {
cmUser_findCar( req: $req ) {
 ${resHead}, ${resPage}
 data { ${carFlds}  }
}
}
`

export const cmUser_getCar = gql`
query cmUser_getCar( $req:CarReq! ) {
cmUser_getCar( req: $req ) {
 ${resHead}
 data { ${carFlds}  }
}
}
`

export const cmUser_addCar = gql`
mutation cmUser_addCar( $req:CmCarReq! ) {
cmUser_addCar( req: $req ) {
 ${resHead}
 data { ${carFlds}, ${rscFields} }
}
}
`

export const cmUser_putCar = gql`
mutation cmUser_putCar( $req:CmCarReq! ) {
cmUser_putCar( req: $req ) {
 ${resHead}
 data { ${carFlds},   ${rscFields} }
}
}
`

export const cmUser_delCar = gql`
mutation cmUser_delCar($req:CmCarReq! ) {
cmUser_delCar( req: $req ) {
 ${resHead}
 data { ${carFlds},   ${rscFields} }
}
}
`
/************************
 * Bbs
 */
export const bbs_findBoard = gql`
  query bbs_findBoard( $req:BbsBoardFindReq ) {
    bbs_findBoard( req: $req ) {
      ${resHead}, ${resPage}
      data {  id,type,name,note }
    }
  }
`

const articleFlds = `id, bid, type, subType, title, code, body, ownerName, ownerId, stat, hitCount,
       favorCount, likeCount, replyCount , myFavor, myLike,score, refType, refId, writer {${userCols}}`
const articleReplyFlds =
  'replies { id, aid, bid,  body, ownerName, ownerId, createdAt } '
const attachFlds =
  'attaches  { id , srcId,srcType, filename, refId,refType, linkUrl, seq }'
export const bbs_findArticle = gql`
  query bbs_findArticle( $req:BbsArticleFindReq ) {
    bbs_findArticle( req: $req ) {
      ${resHead}, ${resPage}
      data { ${articleFlds}, ${articleReplyFlds}, ${rscFields}, ${attachFlds} }
    }
  }
`

export const bbs_getArticle = gql`
  query bbs_getArticle( $req:BbsArticleReq! ) {
    bbs_getArticle( req: $req ) {
      ${resHead}
      data { ${articleFlds}, ${articleReplyFlds}, ${rscFields}, ${attachFlds} }
    }
  }
`

export const bbs_addArticle = gql`
mutation bbs_addArticle( $req:BbsArticleReq! ) {
    bbs_addArticle( req: $req ) {
      ${resHead}
      data { ${articleFlds}, ${rscFields} }
    }
  }
`

export const bbs_putArticle = gql`
mutation bbs_putArticle( $req:BbsArticleReq! ) {
    bbs_putArticle( req: $req ) {
      ${resHead}
      data { ${articleFlds},   ${rscFields} }
    }
  }
`

export const bbs_delArticle = gql`
mutation bbs_delArticle( $req:BbsArticleReq! ) {
    bbs_delArticle( req: $req ) {
      ${resHead}
      data { ${articleFlds},   ${rscFields} }
    }
  }
`

export const bbs_addAttach = gql`
mutation bbs_addAttach( $req:BbsAttachReq! ) {
  bbs_addAttach( req: $req ) {
      ${resHead}
      data {
        id
        srcType
        srcId
        type
        filename
        fileType
        size
        linkUrl
        refType
        refId
        seq
        ${rscFields}
      }
    }
  }
`

export const bbs_putAttach = gql`
mutation bbs_putAttach( $req:BbsAttachReq! ) {
  bbs_putAttach( req: $req ) {
      ${resHead}
      data {
        id
        srcType
        srcId
        type
        filename
        fileType
        size
        linkUrl
        refType
        refId
        seq
        ${rscFields}
      }
    }
  }
`

export const bbs_delAttach = gql`
mutation bbs_delAttach( $req:BbsAttachReq! ) {
  bbs_delAttach( req: $req ) {
      ${resHead}
      data {
        id
        srcType
        srcId
        type
        filename
        fileType
        size
        linkUrl
        refType
        refId
        seq
        ${rscFields}
      }
    }
  }
`

const favorFlds = `id,type,bid,code,aid,rid,refType,refId,isFavor,ownerId,ownerName,createdAt,createdBy`
export const bbs_addFavor = gql`
mutation bbs_addFavor( $req:BbsFavorReq! ) {
  bbs_addFavor( req: $req ) {
      ${resHead}
      data { ${favorFlds},   ${rscFields} }
    }
  }
`

export const bbs_putFavor = gql`
mutation bbs_putFavor( $req:BbsFavorReq! ) {
  bbs_putFavor( req: $req ) {
      ${resHead}
      data { ${favorFlds},   ${rscFields} }
    }
  }
`

export const bbs_putLike = gql`
mutation bbs_putLike( $req:BbsArticleReq! ) {
  bbs_putLike( req: $req ) {
      ${resHead}
      data { ${articleFlds},   ${rscFields} }
    }
  }
`

/*****************
 *  Reply
 */
const replyCols = `id, aid, bid,  body, ownerName, ownerId, createdAt` //'id, bid, aid, body'
export const bbs_findReply = gql`
query bbs_findReply($req:BbsReplyFindReq) {
 bbs_findReply(req:$req){
       ${resHead}, ${resPage}
        data { ${replyCols} }
     }
}`

export const bbs_getReply = gql`
query bbs_getReply($req:BbsReplyReq) {
 bbs_getReply(req:$req){
       ${resHead}
        data { ${replyCols} }
     }
}`

export const bbs_addReply = gql`
mutation bbs_addReply($req:BbsReplyReq!) {
 bbs_addReply(req:$req){
       ${resHead}
        data { ${replyCols} }
     }
}`

export const bbs_putReply = gql`
mutation bbs_putReply($req:BbsReplyReq!) {
 bbs_putReply(req:$req){
       ${resHead}
        data { ${replyCols} }
     }
}`

export const bbs_delReply = gql`
mutation bbs_delReply($req:BbsReplyMutReq!) {
 bbs_delReply(req:$req){
       ${resHead}
        data { ${replyCols} }
     }
}`

/*************
 *
 * */
const notiCols = `pid,channels,id,code,type,subType,title,body,data,fromType,fromId,fromName,toType,toId,toName,toParam,refType,refId,ownerId,ownerName,createdAt,createdBy`
export const noti_findNoti = gql`
query noti_findNoti($req:CmNotiFindReq!) {
 noti_findNoti(req:$req){
       ${resHead}, ${resPage}
        data { ${notiCols} }
     }
}`

export const noti_getNoti = gql`
query noti_getNoti($req:CmNotiReq!) {
 noti_getNoti(req:$req){
       ${resHead}
        data { ${notiCols} }
     }
}`

export const noti_addNoti = gql`
mutation noti_addNoti($req:CmNotiReq!) {
 noti_addNoti(req:$req){
       ${resHead}
        data { ${notiCols} }
     }
}`

export const noti_sendNoti = gql`
mutation noti_sendNoti($req:CmNotiReq!) {
  noti_sendNoti(req:$req){
       ${resHead}
        data { ${notiCols} }
     }
}`

export const noti_sendFcm = gql`
mutation noti_sendFcm($req:CmNotiReq!) {
  noti_sendFcm(req:$req){
       ${resHead}
        data { ${notiCols} }
     }
}`

export const noti_putNoti = gql`
mutation noti_putNoti($req:CmNotiReq!) {
 noti_putNoti(req:$req){
       ${resHead}
        data { ${notiCols} }
     }
}`

export const noti_delNoti = gql`
mutation noti_delNoti($req:CmNotiReq!) {
 noti_delNoti(req:$req){
       ${resHead}
        data { ${notiCols} }
     }
}`

/**
 *

bbs_addAttach(req: BbsAttachReq): BbsAttachRes
bbs_putAttach(req: BbsAttachReq): BbsAttachRes
bbs_delAttach(req: BbsAttachReq): BbsAttachRes

bbs_addLike(req: BbsLikeReq): BbsLikeRes
bbs_addFavor(req: BbsFavorReq): BbsFavorRes

pds_putFile(req: RzFileReq): RzFileRes
pds_delFile(req: RzFileReq): RzFileRes
 */

/**************
 * Reservation
 */
const weatherCols = `date, loc, locDetail, sky, degree`
export const cmn_getWeather = gql`
  query cmn_getWeather($req:WeatherReq) {
    cmn_getWeather(req:$req){
         ${resHead}
          data { ${weatherCols} }
       }
  }`
export const cmn_findWeather = gql`
  query cmn_findWeather($req:WeatherFindReq) {
    cmn_findWeather(req:$req){
         ${resHead}, ${resPage}
          data { ${weatherCols} }
       }
  }`

/**************
 * Reservation
 */

//washType, washCourse, washOpts,
const cpnCols = `id,
  shopId,
  code,

  type,
  title,
  usedCount,
  validCount,
  usedStat,

  loc, locTitle,
  shopTitle,
  shopId,
  carRegNo,
  carId,
  stat,
  taskId,
  ${taskCols}

  price, unit, payAmount, payUnit,payStat,

  note ,
  nextPayDate,
  ownerId,
  ownerName,
  createdAt,
  createdBy,
  updatedAt,
  expiredAt,
  cpnExpireDay`

const rsvColsCpn = `coupon {${cpnCols}}`
const rsvColsShop = `shop {${shopCols}}`
const rsvColsCar = `car {${carFlds}}`

export const cmRsv_findRsv = gql`
query cmRsv_findRsv($req:CmRsvFindReq) {
  cmRsv_findRsv(req:$req){
       ${resHead} , ${resPage}
        data { ${rsvCols}, ${rsvColsCpn}, ${rsvColsShop}, ${rsvColsCar} }
     }
}`

//cmRsv_getRsv(req: CmRsvReq): CmRsvRes
export const cmRsv_getRsv = gql`
 query cmRsv_getRsv($req:CmRsvReq) {
  cmRsv_getRsv(req:$req){
        ${resHead}
         data { ${rsvCols} }
      }
 }`

//cmRsv_addRsv(req: CmRsvReq): CmRsvRes
export const cmRsv_addRsv = gql`
 mutation cmRsv_addRsv($req:CmRsvReq) {
  cmRsv_addRsv(req:$req){
        ${resHead}
         data { ${rsvCols} }
      }
 }`

//cmRsv_putRsv(req: CmRsvReq): CmRsvRes
export const cmRsv_putRsv = gql`
 mutation cmRsv_putRsv($req:CmRsvReq) {
  cmRsv_putRsv(req:$req){
        ${resHead}
         data { ${rsvCols} }
      }
 }`

//cmRsv_delRsv(req: CmRsvReq): CmRsvRes
export const cmRsv_delRsv = gql`
 mutation cmRsv_delRsv($req:CmRsvReq) {
  cmRsv_delRsv(req:$req){
        ${resHead}
         data { ${rsvCols} }
      }
 }`

/*********
 *  GIFT
 */
const giftCols = `id,code,type,title,cpnId,shopId,shopTitle,carRegNo,senderId,senderName,receiverPhone,ownerId,ownerName,note`

//cmCpn_findGift(req: CmGiftFindReq): CmGiftRsRes
export const cmCpn_findGift = gql`
 query cmCpn_findGift($req:CmGiftFindReq) {
  cmCpn_findGift(req:$req){
        ${resHead}, ${resPage}
         data { ${giftCols} }
      }
 }`

//cmCpn_getGift(req: CmGiftReq): CmGiftRes
export const cmCpn_getGift = gql`
 query cmCpn_getGift($req:CmGiftReq) {
  cmCpn_getGift(req:$req){
        ${resHead}
         data { ${giftCols} }
      }
 }`

/*********
 *  GIFT MODEL
 */
const giftModelCols = `id, type,ownerId,ownerName, title  ,
  shopId, shopTitle, schdBegAt, carId,carRegNo,
  washType,washOpts,washCourse,amount,unit,
   payAmount, payUnit, stat , type
  `
// cmCpn_findGiftModel(req: CmGiftModelFindReq): CmGiftModelRsRes
export const cmCpn_findGiftModel = gql`
 query cmCpn_findGiftModel($req:CmGiftModelFindReq) {
  cmCpn_findGiftModel(req:$req){
        ${resHead}
         data { ${giftModelCols} }
      }
 }`

//cmCpn_getGiftModel(req: CmGiftModelReq): CmGiftModelRes
export const cmCpn_getGiftModel = gql`
 query cmCpn_getGiftModel($req:CmGiftModelReq) {
  cmCpn_getGiftModel(req:$req){
        ${resHead}
         data { ${giftModelCols} }
      }
 }`

/* CpnModel *************/

/*******
 * GiftModel
 */

//cmCpn_addGiftModel(req: CmGiftModelReq): CmGiftModelRes
export const cmCpn_addGiftModel = gql`
 mutation cmCpn_addGiftModel($req:CmGiftModelReq) {
  cmCpn_addGiftModel(req:$req){
        ${resHead}
         data { ${giftModelCols} }
      }
 }`

//cmCpn_putGiftModel(req: CmGiftModelReq): CmGiftModelRes
export const cmCpn_putGiftModel = gql`
 mutation cmCpn_putGiftModel($req:CmGiftModelReq) {
  cmCpn_putGiftModel(req:$req){
        ${resHead}
         data { ${giftModelCols} }
      }
 }`

//cmCpn_delGiftModel(req: CmGiftModelReq): CmGiftModelRes
export const cmCpn_delGiftModel = gql`
 mutation cmCpn_delGiftModel($req:CmGiftModelReq) {
  cmCpn_delGiftModel(req:$req){
        ${resHead}
         data { ${giftModelCols} }
      }
 }`

/* Gift *************/
//cmCpn_addGift(req: CmGiftReq): CmGiftRes
export const cmCpn_addGift = gql`
 mutation cmCpn_addGift($req:CmGiftReq) {
  cmCpn_addGift(req:$req){
        ${resHead}
         data { ${giftCols} }
      }
 }`

//cmCpn_putGift(req: CmGiftReq): CmGiftRes
export const cmCpn_putGift = gql`
 mutation cmCpn_putGift($req:CmGiftReq) {
  cmCpn_putGift(req:$req){
        ${resHead}
         data { ${giftCols} }
      }
 }`

//cmCpn_delGift(req: CmGiftReq): CmGiftRes
export const cmCpn_delGift = gql`
 mutation cmCpn_delGift($req:CmGiftReq) {
  cmCpn_delGift(req:$req){
        ${resHead}
         data { ${giftCols} }
      }
 }`

/************
 * Pay
 */

export const cmPay_findPay = gql`
 query cmPay_findPay($req:CmPayFindReq) {
  cmPay_findPay(req:$req){
        ${resHead}
         data { ${payCols}, ${rscFields} }
      }
 }`

//cmPay_getPay(req: CmPayReq): CmPayRes
export const cmPay_getPay = gql`
 query cmPay_getPay($req:CmPayReq) {
  cmPay_getPay(req:$req){
        ${resHead}
         data { ${payCols} }
      }
 }`

//cmPay_addPay(req: CmPayReq): CmPayRes
export const cmPay_addPay = gql`
 mutation cmPay_addPay($req:CmPayReq) {
  cmPay_addPay(req:$req){
        ${resHead}
         data { ${payCols} }
      }
 }`

//cmPay_putPay(req: CmPayReq): CmPayRes
export const cmPay_putPay = gql`
mutation cmPay_putPay($req:CmPayReq) {
  cmPay_putPay(req:$req){
       ${resHead}
        data { ${payCols} }
     }
}`

//cmPay_delPay(req: CmPayReq): CmPayRes
export const cmPay_delPay = gql`
mutation cmPay_delPay($req:CmPayReq) {
  cmPay_delPay(req:$req){
       ${resHead}
        data { ${payCols} }
     }
}`

/* Cash **** */
const cashCols = ` id,type,amount,unit,payAmount,usedAmount, ownerId,ownerName, note`
//cmPay_findCash(req: CmCashFindReq): CmCashRsRes
export const cmPay_findCash = gql`
 query cmPay_findCash($req:CmCashFindReq) {
  cmPay_findCash(req:$req){
        ${resHead}
         data { ${cashCols} }
      }
 }`

//cmPay_getCash(req: CmCashReq): CmCashRes
export const cmPay_getCash = gql`
 query cmPay_getCash($req:CmCashReq) {
  cmPay_getCash(req:$req){
        ${resHead}
         data { ${cashCols} }
      }
 }`
//cmPay_addCash(req: CmCashReq): CmCashRes
export const cmPay_addCash = gql`
mutation cmPay_addCash($req:CmCashReq) {
  cmPay_addCash(req:$req){
       ${resHead}
        data { ${cashCols} }
     }
}`

//cmPay_putCash(req: CmCashReq): CmCashRes
export const cmPay_putCash = gql`
mutation cmPay_putCash($req:CmCashReq) {
  cmPay_putCash(req:$req){
       ${resHead}
        data { ${cashCols} }
     }
}`

//cmPay_delCash(req: CmCashReq): CmCashRes
export const cmPay_delCash = gql`
mutation cmPay_delCash($req:CmCashReq) {
  cmPay_delCash(req:$req){
       ${resHead}
        data { ${cashCols} }
     }
}`

/**********
 *  SHOP
 */

//cmShop_findShop(req: CmShopFindReq): CmShopRsRes

export const cmShop_findShop = gql`
query cmShop_findShop($req:CmShopFindReq) {
  cmShop_findShop(req:$req){
       ${resHead} ${resPage}
        data { ${shopCols} }
     }
}`

//cmShop_getShop(req: CmShopReq): CmShopRes
export const cmShop_getShop = gql`
query cmShop_getShop($req:CmShopReq) {
  cmShop_getShop(req:$req){
       ${resHead}
        data { ${shopCols}, shopInfo{ ${shopinfoCols} }   }
     }
}`

//cmShop_addShop(req: CmShopReq): CmShopRes
export const cmShop_addShop = gql`
mutation cmShop_addShop($req:CmShopReq) {
  cmShop_addShop(req:$req){
       ${resHead}
        data { ${shopCols} }
     }
}`

//cmShop_putShop(req: CmShopReq): CmShopRes
export const cmShop_putShop = gql`
mutation cmShop_putShop($req:CmShopReq) {
  cmShop_putShop(req:$req){
       ${resHead}
        data { ${shopCols} }
     }
}`

export const cmShop_addShopInfo = gql`
mutation cmShop_addShopInfo($req:CmShopInfoReq) {
  cmShop_addShopInfo(req:$req){
       ${resHead}
        data { ${shopinfoCols} }
     }
}`

export const cmShop_putShopInfo = gql`
mutation cmShop_putShopInfo($req:CmShopInfoReq) {
  cmShop_putShopInfo(req:$req){
       ${resHead}
        data { ${shopinfoCols} }
     }
}`

//cmShop_delShop(req: CmShopReq): CmShopRes
export const cmShop_delShop = gql`
mutation cmShop_delShop($req:CmShopReq) {
  cmShop_delShop(req:$req){
       ${resHead}
        data { ${shopCols} }
     }
}`

const reviewFlds = `
  ${articleFlds}, ${articleReplyFlds}, ${rscFields}
  wash { ${washCols} }
  shop { ${shopCols} }
  writerImageUrl,
  treatBegAt,
  writer { ${userCols} }
`

export const cmShop_findReview = gql`
query cmShop_findReview($req:CmReviewFindReq) {
  cmShop_findReview(req:$req){
    ${resHead} ${resPage}
      data {
          ${reviewFlds}
      }
  }}
`

export const cmShop_findArticle = gql`
query cmShop_findArticle( $req:BbsArticleFindReq ) {
  cmShop_findArticle( req: $req ) {
    ${resHead}, ${resPage}
    data { ${articleFlds}, ${articleReplyFlds}, ${rscFields}, ${attachFlds} }
  }
}`

//const equipCols=`id, shopId, type, code, title, qty, unit, note,stat`

export const cmShop_findShopEquip = gql`
query cmShop_findShopEquip($req:CmShopEquipFindReq) {
  cmShop_findShopEquip(req:$req){
    ${resHead} ${resPage}
      data { ${equipCols}}
  }}
`

export const cmShop_addShopEquip = gql`
mutation cmShop_addShopEquip($req:CmShopEquipReq) {
  cmShop_addShopEquip(req:$req){
        ${resHead}
        data { ${equipCols} }
      }
}`

export const cmShop_putShopEquip = gql`
mutation cmShop_putShopEquip($req:CmShopEquipReq) {
  cmShop_putShopEquip(req:$req){
        ${resHead}
        data { ${equipCols} }
      }
}`

export const cmShop_delShopEquip = gql`
mutation cmShop_delShopEquip($req:CmShopEquipReq) {
  cmShop_delShopEquip(req:$req){
        ${resHead}
        data { ${equipCols} }
      }
}`

/**
 * WashPod
 */

const washPodActStatCols = ` id, washPodId,nextDayRsvCount,rsvCount, actStat,procCount,kioskStat `
const washBriefFld = `washPodId,courseTitle,courseCount,optTitle,optCount,`
const washPodCols = `id,shopId,regNo,model,modelName,title,note,type,stat,authCode,validWashCount,ownerId,ownerName,
      actStat { ${washPodActStatCols} },
      washCourses { ${washCourseFld}},
      washOpts {${washOptFld}},
      carTypes ,
      carAttaches {${washOptFld}},
      washBrief {${washBriefFld}},
      shop {${shopCols}}
 `

const fetchWashPodCols = `id,shopId,regNo,model,modelName,title,note,type,stat,authCode,validWashCount,ownerId,ownerName,
      actStat { ${washPodActStatCols} },
      washCourses { ${washCourseFld}},
      washOpts {${washOptFld}},
      carTypes ,
      carAttaches {${washOptFld}}
 `

//cmShop_findWashPod(req: CmWashPodFindReq): CmWashPodRsRes
export const cmShop_findWashPod = gql`
query cmShop_findWashPod($req:CmWashPodFindReq) {
  cmShop_findWashPod(req:$req){
       ${resHead} ${resPage}
        data { ${washPodCols} }
     }
}`

//cmShop_getWashPod(req: CmWashPodReq): CmWashPodRes
export const cmShop_getWashPod = gql`
query cmShop_getWashPod($req:CmWashPodReq) {
  cmShop_getWashPod(req:$req){
       ${resHead}
        data { ${washPodCols}  }
     }
}`

//cmShop_addWashPod(req: CmWashPodReq): CmWashPodRes
export const cmShop_addWashPod = gql`
mutation cmShop_addWashPod($req:CmWashPodReq) {
  cmShop_addWashPod(req:$req){
       ${resHead}
        data { ${fetchWashPodCols} }
     }
}`

//cmShop_putWashPod(req: CmWashPodReq): CmWashPodRes
export const cmShop_putWashPod = gql`
mutation cmShop_putWashPod($req:CmWashPodReq) {
  cmShop_putWashPod(req:$req){
       ${resHead}
        data { ${fetchWashPodCols} }
     }
}`

//cmShop_delWashPod(req: CmWashPodReq): CmWashPodRes
export const cmShop_delWashPod = gql`
mutation cmShop_delWashPod($req:CmWashPodReq) {
  cmShop_delWashPod(req:$req){
       ${resHead}
        data { ${fetchWashPodCols} }
     }
}`

/**
 * Wash
 */

//cmShop_findWash(req: CmWashFindReq): CmWashRsRes
export const cmWash_findWash = gql`
query cmWash_findWash($req:CmWashFindReq) {
  cmWash_findWash(req:$req){
       ${resHead} ${resPage}
        data { ${washCols} }
     }
}`

export const cmWash_getWash = gql`
query cmWash_getWash($req:CmWashReq) {
  cmWash_getWash(req:$req){
       ${resHead}
        data { ${washCols} }
     }
}`

export const cmWash_addWash = gql`
mutation cmWash_addWash($req:CmWashReq) {
  cmWash_addWash(req:$req){
       ${resHead}
        data { ${washCols} }
     }
}`

//cmShop_putWash(req: CmWashReq): CmWashRes
export const cmWash_putWash = gql`
mutation cmWash_putWash($req:CmWashReq) {
  cmWash_putWash(req:$req){
       ${resHead}
        data { ${washCols} }
     }
}`

//cmShop_delWash(req: CmWashReq): CmWashRes
export const cmWash_delWash = gql`
mutation cmWash_delWash($req:CmWashReq) {
  cmWash_delWash(req:$req){
       ${resHead}
        data { ${washCols} }
     }
}`

/**
 * WashCourse
 */

export const cmWash_findWashCourse = gql`
 query cmWash_findWashCourse($req:CmWashCourseFindReq) {
   cmWash_findWashCourse(req:$req){
        ${resHead} ${resPage}
         data { ${washCourseFld} }
      }
 }`

export const cmWash_getWashCourse = gql`
 query cmWash_getWashCourse($req:CmWashCourseReq) {
   cmWash_getWashCourse(req:$req){
        ${resHead}
         data { ${washCourseFld} }
      }
 }`

export const cmWash_addWashCourse = gql`
 mutation cmWash_addWashCourse($req:CmWashCourseReq) {
   cmWash_addWashCourse(req:$req){
        ${resHead}
         data { ${washCourseFld} }
      }
 }`

//cmWash_putWashCourse(req: CmWashReq): CmWashRes
export const cmWash_putWashCourse = gql`
 mutation cmWash_putWashCourse($req:CmWashCourseReq) {
   cmWash_putWashCourse(req:$req){
        ${resHead}
         data { ${washCourseFld} }
      }
 }`

//cmWash_delWashCourse(req: CmWashReq): CmWashRes
export const cmWash_delWashCourse = gql`
 mutation cmWash_delWashCourse($req:CmWashCourseReq) {
   cmWash_delWashCourse(req:$req){
        ${resHead}
         data { ${courseCols} }
      }
 }`

/**
 * WashOpt
 */

export const cmWash_findWashOpt = gql`
 query cmWash_findWashOpt($req:CmWashOptFindReq) {
   cmWash_findWashOpt(req:$req){
        ${resHead} ${resPage}
         data { ${washOptFld} }
      }
 }`

export const cmWash_getWashOpt = gql`
 query cmWash_getWashOpt($req:CmWashOptReq) {
   cmWash_getWashOpt(req:$req){
        ${resHead}
         data { ${washOptFld} }
      }
 }`

export const cmWash_addWashOpt = gql`
 mutation cmWash_addWashOpt($req:CmWashOptReq) {
   cmWash_addWashOpt(req:$req){
        ${resHead}
         data { ${washOptFld} }
      }
 }`

//cmWash_putWashOpt(req: CmWashReq): CmWashRes
export const cmWash_putWashOpt = gql`
 mutation cmWash_putWashOpt($req:CmWashOptReq) {
   cmWash_putWashOpt(req:$req){
        ${resHead}
         data { ${washOptFld} }
      }
 }`

//cmWash_delWashOpt(req: CmWashReq): CmWashRes
export const cmWash_delWashOpt = gql`
 mutation cmWash_delWashOpt($req:CmWashOptReq) {
   cmWash_delWashOpt(req:$req){
        ${resHead}
         data { ${washOptFld} }
      }
 }`

/**
 * Stat
 */
const statRowFlds = `
id, statId, seq, kind, subKind, loc1,loc2, loc3, title, valType, value, refValue, stat
`

const statFld = `
  id, shopId, code, title, type, stat , issuedAt,issuedBy,
  rows { ${statRowFlds} }`

export const cmStat_findStat = gql`
  query cmStat_findStat($req:CmStatFindReq) {
    cmStat_findStat(req:$req){
         ${resHead} ${resPage}
          data { ${statFld} }
       }
  }`

export const cmStat_getStat = gql`
  query cmStat_getStat($req:CmStatReq) {
    cmStat_getStat(req:$req){
         ${resHead}
          data { ${statFld} }
       }
  }`

export const cmStat_addStat = gql`
  mutation cmStat_addStat($req:CmStatReq) {
    cmStat_addStat(req:$req){
         ${resHead}
          data { ${statFld} }
       }
  }`

//cmStat_putStat(req: CmWashReq): CmWashRes
export const cmStat_putStat = gql`
  mutation cmStat_putStat($req:CmStatReq) {
    cmStat_putStat(req:$req){
         ${resHead}
          data { ${statFld} }
       }
  }`

//cmStat_delStat(req: CmWashReq): CmWashRes
export const cmStat_delStat = gql`
  mutation cmStat_delStat($req:CmStatReq) {
    cmStat_delStat(req:$req){
         ${resHead}
          data { ${statFld} }
       }
  }`
/********
 *  CreditCard
 */
const creditCardCols = `
id,type,ownerId,ownerName,
      cardNo, holderName, cardCo, authNo,stat , isDefault `

export const cmPay_findCreditCard = gql`
query cmPay_findCreditCard($req:CmCreditCardFindReq) {
  cmPay_findCreditCard(req:$req){
        ${resHead} ${resPage}
        data { ${creditCardCols} }
      }
}`

export const cmPay_getCreditCard = gql`
query cmPay_getCreditCard($req:CmCreditCardReq) {
  cmPay_getCreditCard(req:$req){
        ${resHead}
        data { ${creditCardCols} }
      }
}`

export const cmPay_addCreditCard = gql`
mutation cmPay_addCreditCard($req:CmCreditCardReq) {
  cmPay_addCreditCard(req:$req){
        ${resHead}
        data { ${creditCardCols} }
      }
}`

//cmPay_putCreditCard(req: CmWashReq): CmWashRes
export const cmPay_putCreditCard = gql`
mutation cmPay_putCreditCard($req:CmCreditCardReq) {
  cmPay_putCreditCard(req:$req){
        ${resHead}
        data { ${creditCardCols} }
      }
}`

//cmPay_delCreditCard(req: CmWashReq): CmWashRes
export const cmPay_delCreditCard = gql`
mutation cmPay_delCreditCard($req:CmCreditCardReq) {
  cmPay_delCreditCard(req:$req){
        ${resHead}
        data { ${creditCardCols} }
      }
}`

/**********
 *
 */
const settleCols = `id,type,title,issuedAt,settledAt,stat `

export const cmPay_findSettle = gql`
 query cmPay_findSettle($req:CmSettleFindReq) {
   cmPay_findSettle(req:$req){
        ${resHead} ${resPage}
         data { ${settleCols} }
      }
 }`

export const cmPay_getSettle = gql`
 query cmPay_getSettle($req:CmSettleReq) {
   cmPay_getSettle(req:$req){
        ${resHead}
         data { ${settleCols} }
      }
 }`

export const cmPay_addSettle = gql`
 mutation cmPay_addSettle($req:CmSettleReq) {
   cmPay_addSettle(req:$req){
        ${resHead}
         data { ${settleCols} }
      }
 }`

//cmShop_putSettle(req: CmSettleReq): CmSettleRes
export const cmPay_putSettle = gql`
 mutation cmPay_putSettle($req:CmSettleReq) {
   cmPay_putSettle(req:$req){
        ${resHead}
         data { ${settleCols} }
      }
 }`

//cmShop_delSettle(req: CmSettleReq): CmSettleRes
export const cmPay_delSettle = gql`
 mutation cmPay_delSettle($req:CmSettleReq) {
   cmPay_delSettle(req:$req){
        ${resHead}
         data { ${settleCols} }
      }
 }`

/**********
 *
 */
const settleRowCols = `
id, settleId,seq,type,shopId,washId,shopTitle,washTitle,customerId,customerName,amount,payAmount,settleAmount,
settledAt,settledBy,settleStat,stat,
`

export const cmPay_findSettleRow = gql`
query cmPay_findSettleRow($req:CmSettleRowFindReq) {
  cmPay_findSettleRow(req:$req){
       ${resHead} ${resPage}
        data {
          ${settleRowCols}
          wash { ${washCols} }
        }
     }
}`

export const cmPay_getSettleRow = gql`
query cmPay_getSettleRow($req:CmSettleRowReq) {
  cmPay_getSettleRow(req:$req){
       ${resHead}
        data { ${settleRowCols} }
     }
}`

export const cmPay_addSettleRow = gql`
mutation cmPay_addSettleRow($req:CmSettleRowReq) {
  cmPay_addSettleRow(req:$req){
       ${resHead}
        data { ${settleRowCols} }
     }
}`

//cmShop_putSettleRow(req: CmSettleRowReq): CmSettleRowRes
export const cmPay_putSettleRow = gql`
mutation cmPay_putSettleRow($req:CmSettleRowReq) {
  cmPay_putSettleRow(req:$req){
       ${resHead}
        data { ${settleRowCols} }
     }
}`

//cmShop_delSettleRow(req: CmSettleRowReq): CmSettleRowRes
export const cmPay_delSettleRow = gql`
mutation cmPay_delSettleRow($req:CmSettleRowReq) {
  cmPay_delSettleRow(req:$req){
       ${resHead}
        data { ${settleRowCols} }
     }
}`

/*************
 *  CpnModel
 */

// cmCpn_findCpnModel(req: CmCpnModelFindReq): CmCpnModelRsRes
const cpnModelCols = `id,
type
title
carType
amount
unit

washCourseId
washOptId
note
stat

ownerId,
ownerName,
createdAt,
createdBy,
washPodModelNo,
washPodModelName,

maxUsageCount,

actStat { buyCount, unusedCount }
washPodModel { ${washPodCols} }
`

export const cmCpn_findCpnModel = gql`
query cmCpn_findCpnModel($req:CmCpnModelFindReq) {
  cmCpn_findCpnModel(req:$req){
       ${resHead} , ${resPage}
        data { ${cpnModelCols} }
     }
}`

// cmCpn_getCpnModel(req: CmCpnModelReq): CmCpnModelRes
export const cmCpn_getCpnModel = gql`
query cmCpn_getCpnModel($req:CmCpnModelReq) {
  cmCpn_getCpnModel(req:$req){
       ${resHead}
        data { ${cpnModelCols} }
     }
}`

//cmCpn_findCpn(req: CmCpnFindReq): CmCpnRsRes
export const cmCpn_lightFindCpn = gql`
query cmCpn_lightFindCpn($req:CmCpnFindReq) {
 cmCpn_findCpn(req:$req){
       ${resHead}, ${resPage}
        data { id, shopId ownerId, code, stat, usedStat, createdAt, modelId }
     }
}`

export const cmCpn_findCpn = gql`
 query cmCpn_findCpn($req:CmCpnFindReq) {
  cmCpn_findCpn(req:$req){
        ${resHead}, ${resPage}
         data { ${cpnCols} }
      }
 }`

//cmCpn_getCpn(req: CmCpnReq): CmCpnRes
export const cmCpn_getCpn = gql`
 query cmCpn_getCpn($req:CmCpnReq) {
  cmCpn_getCpn(req:$req){
        ${resHead}
         data { ${cpnCols} }
      }
 }`

//cmCpn_addCpn(req: CmCpnReq): CmCpnRes
export const cmCpn_addCpn = gql`
mutation cmCpn_addCpn($req:CmCpnReq) {
 cmCpn_addCpn(req:$req){
       ${resHead}
        data { ${cpnCols} }
     }
}`

//cmCpn_putCpn(req: CmCpnReq): CmCpnRes
export const cmCpn_putCpn = gql`
mutation cmCpn_putCpn($req:CmCpnReq) {
 cmCpn_putCpn(req:$req){
       ${resHead}
        data { ${cpnCols} }
     }
}`

//cmCpn_delCpn(req: CmCpnReq): CmCpnRes
export const cmCpn_delCpn = gql`
mutation cmCpn_delCpn($req:CmCpnReq) {
 cmCpn_putCpn(req:$req){
       ${resHead}
        data { ${cpnCols} }
     }
}`
//cmCpn_addCpnModel(req: CmCpnModelReq): CmCpnModelRes
export const cmCpn_addCpnModel = gql`
 mutation cmCpn_addCpnModel($req:CmCpnModelReq) {
  cmCpn_addCpnModel(req:$req){
        ${resHead}
         data { ${cpnModelCols} }
      }
 }`

//cmCpn_putCpnModel(req: CmCpnModelReq): CmCpnModelRes
export const cmCpn_putCpnModel = gql`
 mutation cmCpn_putCpnModel($req:CmCpnModelReq) {
  cmCpn_putCpnModel(req:$req){
        ${resHead}
         data { ${cpnModelCols} }
      }
 }`

//cmCpn_delCpnModel(req: CmCpnModelReq): CmCpnModelRes
export const cmCpn_delCpnModel = gql`
 mutation cmCpn_delCpnModel($req:CmCpnModelReq) {
  cmCpn_delCpnModel(req:$req){
        ${resHead}
         data { ${cpnModelCols} }
      }
 }`
